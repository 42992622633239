export function state() {
  return {
    list: [],
  }
}

export const mutations = {
  SET_ITEMS: (state, tickets) => (state.list = tickets),
}

export const actions = {
  fetchItems({ commit, dispatch, state }, id) {
    dispatch('wait/start', 'customers.pickups.index', { root: true })

    return this.$axios
      .$get(`/customers/${id}/pickups`, {
        headers: {
          'X-Company-Id': localStorage.getItem('company-id'),
        },
      })
      .then((data) => {
        commit('SET_ITEMS', data.data)
        dispatch('wait/end', 'customers.pickups.index', { root: true })
        return Promise.resolve(state.list)
      })
      .catch(() => {
        dispatch('wait/end', 'customers.pickups.index', { root: true })
      })
  },
  create({ dispatch }, pickup) {
    dispatch('wait/start', 'customers.pickups.create', { root: true })
    return this.$axios
      .$post(
        `/customers/${pickup.customer_id}/pickups`,
        {
          tickets_ids: pickup.tickets_ids,
          avatar: pickup.avatar,
          signature: pickup.signature,
          picked_up_time: pickup.picked_up_time,
        },
        {
          headers: {
            'X-Company-Id': localStorage.getItem('company-id'),
          },
        },
      )
      .then((data) => {
        dispatch('wait/end', 'customers.pickups.create', { root: true })
        this.$notify({
          type: 'success',
          title: 'Pickup Item(s)',
          text: data.message,
        })
        return Promise.resolve(data.data)
      })
      .catch((e) => {
        if (e.response && e.response.status === 422) {
          this.app.injectServerErrors(
            'customers.pickups.create',
            e.response.data.errors,
          )
        }
        dispatch('wait/end', 'customers.pickups.create', { root: true })
        return Promise.reject(e)
      })
  },
}
