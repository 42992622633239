export function state() {
  return {
    list: [],
  }
}

export const mutations = {
  SET_ITEMS: (state, coupons) => (state.list = coupons),
}

export const actions = {

  async fetchItem(_, id) {
    const data = await this.$axios.$get(`/coupons/${id}`, {
      headers: {
        'X-Company-Id': localStorage.getItem('company-id'),
      },
    })
    return Promise.resolve(data.data)
  },
  async fetchItems({ commit }) {
    const data = await this.$axios.$get('/coupons', {
      headers: {
        'X-Company-Id': localStorage.getItem('company-id'),
      },
    })

    commit('SET_ITEMS', data.data)

    return Promise.resolve(data.data)
  },

  async create({ dispatch }, form) {
    dispatch('wait/start', 'coupons.create', { root: true })
    return await this.$axios.$post('/coupons', form, {
      headers: {
        'X-Company-Id': localStorage.getItem('company-id'),
      },
    }).then((data) => {
      this.$notify({
        type: 'success',
        title: 'Success',
        text: 'Coupons Created',
      })

      dispatch('fetchItems')
      dispatch('wait/end', 'coupons.create', { root: true })
      return Promise.resolve(data.data)
    }).catch((e) => {
      if (e.response && e.response.status === 422) {
        for (e.response.data.error in e.response.data.errors) {
          this.$notify({
            type: 'error',
            title: `Coupon Create`,
            text: e.response.data.errors[e.response.data.error][0],
          })
        }

        this.app.injectServerErrors(
          'coupons.create',
          e.response.data.errors,
        )
      }
      dispatch('wait/end', 'coupons.create', { root: true })
      return Promise.reject(e)
    }).finally(() => {
      dispatch('wait/end', 'coupons.create', { root: true })
    })
  },

  async update({ dispatch }, form) {
    dispatch('wait/start', 'coupons.update', { root: true })
    return await this.$axios.$put(`/coupons/${form.id}`, form, {
      headers: {
        'X-Company-Id': localStorage.getItem('company-id'),
      },
    }).then((data) => {
      this.$notify({
        type: 'success',
        title: 'Success',
        text: 'Coupons Update',
      })

      dispatch('fetchItems')

      return Promise.resolve(data.data)
    }).catch((e) => {
      if (e.response && e.response.status === 422) {
        for (e.response.data.error in e.response.data.errors) {
          this.$notify({
            type: 'error',
            title: `Coupon Update`,
            text: e.response.data.errors[e.response.data.error][0],
          })
        }

        this.app.injectServerErrors(
          'coupons.update',
          e.response.data.errors,
        )
      }
      dispatch('wait/end', 'coupons.update', { root: true })
      return Promise.reject(e)
    })
      .finally(() => {
        dispatch('wait/end', 'coupons.update', { root: true })
      })
  },

  async remove({ dispatch }, id) {
    const data = await this.$axios.$delete(`/coupons/${id}`, {
      headers: {
        'X-Company-Id': localStorage.getItem('company-id'),
      },
    })

    dispatch('fetchItems')

    this.$notify({
      type: 'success',
      title: 'Success',
      text: 'Coupon Removed',
    })

    return Promise.resolve(data)
  },
}

export const getters = {

}
