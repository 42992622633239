import { sanitizeTicket } from '../utils/tickets'

export function state() {
  return {
    list: [],
  }
}

export const mutations = {
  SET_ITEMS: (state, orders) => (state.list = orders),
}

export const actions = {
  fetchItems({ commit, dispatch, state }, params) {
    dispatch('wait/start', 'orders.index', { root: true })

    return this.$axios
      .$get(`/orders`, {
        params,
        headers: {
          'X-Company-Id': localStorage.getItem('company-id'),
        },
      })
      .then((data) => {
        commit('SET_ITEMS', data.data)
        dispatch('wait/end', 'orders.index', { root: true })
        return Promise.resolve(state.list)
      })
      .catch(() => {
        dispatch('wait/end', 'orders.index', { root: true })
      })
  },
  async create({ dispatch, rootState }, order) {
    dispatch('wait/start', 'orders.create', { root: true })
    try {
      const { data, message } = await this.$axios.$post(
        `/orders`,
        {
          ...order,
          terminal_id: rootState.terminals.selected.id,
        },
        {
          headers: {
            'X-Company-Id': localStorage.getItem('company-id'),
          },
        },
      )
      const { customer, amount_owed: amountOwed } = data
      await dispatch(
        'tickets/updateCustomerAmountOwed',
        { customerId: customer.id, amountOwed },
        { root: true },
      )

      this.$notify({
        type: 'success',
        title: 'Order Create',
        text: message,
      })

      return data
    }
    catch (e) {
      this.$notify({
        type: 'error',
        title: 'Order Create',
        text: e.response.data.errors,
      })
      return Promise.reject(e)
    }
    finally {
      dispatch('wait/end', 'orders.create', { root: true })
    }
  },
  removeItem({ dispatch }, id) {
    dispatch('wait/start', 'orders.delete', { root: true })
    return this.$axios
      .$delete(`/orders/${id}`, {
        headers: {
          'X-Company-Id': localStorage.getItem('company-id'),
        },
      })
      .then((data) => {
        dispatch('wait/end', 'orders.delete', { root: true })
        this.$notify({
          type: 'success',
          title: 'Order Delete',
          text: data.message,
        })
        return Promise.resolve(data.data)
      })
      .catch((e) => {
        if (e.response && e.response.status === 422)
          this.app.injectServerErrors('orders.delete', e.response.data.errors)

        dispatch('wait/end', 'orders.delete', { root: true })
        return Promise.reject(e)
      })
  },
  fetchTicketItem({ dispatch }, id) {
    dispatch('wait/start', 'orders.items.fetch', { root: true })
    return this.$axios.$get(`/orders/item/${id}`, {
      headers: {
        'X-Company-Id': localStorage.getItem('company-id'),
      },
    }).then((data) => {
      return Promise.resolve(data.data)
    }).finally(() => {
      dispatch('wait/end', 'orders.items.fetch', { root: true })
    })
  },
  removeTicketItem({ dispatch }, id) {
    dispatch('wait/start', 'orders.items.delete', { root: true })
    return this.$axios
      .$delete(`/orders/item/${id}`, {
        headers: {
          'X-Company-Id': localStorage.getItem('company-id'),
        },
      })
      .then((data) => {
        dispatch('wait/end', 'orders.items.delete', { root: true })
        this.$notify({
          type: 'success',
          title: 'Order Item Delete',
          text: data.message,
        })
        return Promise.resolve(data.data)
      })
      .catch((e) => {
        if (e.response && e.response.status === 422) {
          this.app.injectServerErrors(
            'orders.items.delete',
            e.response.data.errors,
          )
        }
        dispatch('wait/end', 'orders.items.delete', { root: true })
        return Promise.reject(e)
      })
  },
}

function calculateTotalPeaces(tickets) {
  return tickets.reduce((pieces, ticket) => pieces + ticket.total_pieces, 0)
}

export const getters = {
  buildOrder: (state, getters, rootState, rootGetters) => (skeleton) => {
    const locationId = rootState.locations.selected
    const order = { ...skeleton }
    order.customer_id = rootState.tickets.currentCustomer.id
    order.tickets = rootGetters['tickets/openTickets']
      .filter(ticket => ticket.totalPieces)
      .map((ticket) => {
        return sanitizeTicket(ticket, locationId)
      })

    order.total_pieces = calculateTotalPeaces(order.tickets)
    return order
  },
}
