import { ROLES } from './constants'
import { capitalizeFirstLetter, isNullOrUndefined } from '.'

// goes to pos with current customer or opens the customer modal
// always needs a parameter, /pos is error currently TODO: fix
function computePosRoute(store) {
  const baseRoute = '/pos'
  const customer = store.state.tickets.currentCustomer
  if (customer)
    return `${baseRoute}/${customer.id}`

  return (e) => {
    e.preventDefault()
    return store.dispatch('ui/openCustomerSearchModal')
  }
}

function createPosTicketTypeAction(service, { store, router }) {
  return async () => {
    const { id } = store.state.tickets.currentCustomer
    let hasItems = false
    if (!isNullOrUndefined(store.getters['tickets/currentTicket']))
      hasItems = store.getters['tickets/currentTicket'].items.length > 0

    if (hasItems) {
      const customer = store.state.tickets.currentCustomer
      const { id } = await store.dispatch('tickets/openNewTicket', {
        customer,
        service,
      })
      await store.dispatch('tickets/setCurrentTicket', id)
    }
    else {
      await store.dispatch('tickets/setTicketType', service)
    }
    router.push(`/pos/${id}`)
  }
}

function createTicketTypeMenuItem(service, { store, router }) {
  const active
    = isNullOrUndefined(store.getters['tickets/currentTicket'])
    || isNullOrUndefined(store.getters['tickets/currentTicket'].service)
      ? false
      : service.id === store.getters['tickets/currentTicket'].service.id
  return {
    label: service.name,
    icon: service.icon,
    active,
    action: createPosTicketTypeAction(service, { store, router }),
  }
}

// the leftmost sidebar
export function mainMenu({ store, $auth }) {
  return [
    // TODO: use real data for the dashboard
    // {
    //   label: 'Dashboard',
    //   icon: 'dashboard',
    //   to: '/',
    //   exact: true,
    //   roles: [ROLES.MANAGER]
    // },
    {
      label: 'POS',
      icon: 'pos',
      to: computePosRoute(store),
      roles: Object.values(ROLES),
    },
    {
      label: 'Customers',
      icon: 'customers',
      to: '/customers',
      roles: Object.values(ROLES), // everyone
    },
    {
      label: 'Items',
      icon: 'price',
      to: '/prices',
      roles: [ROLES.MANAGER],
    },
    {
      label: 'Coupons',
      icon: 'coupon',
      to: '/coupons',
      roles: [ROLES.MANAGER, $auth.user.is_company_owner],
    },
    {
      label: 'Services',
      icon: 'services',
      to: '/services',
      roles: [ROLES.MANAGER, $auth.user.is_company_owner],
    },
    {
      label: 'Location',
      icon: 'location',
      to: '/locations',
      roles: [ROLES.MANAGER, $auth.user.is_company_owner],
    },
    {
      label: 'Employees',
      icon: 'employees',
      to: '/employees',
      roles: [ROLES.MANAGER, $auth.user.is_company_owner],
    },
    // TODO: expand schedules + timesheets pages
    // {
    //   label: 'Schedules',
    //   icon: 'schedule', // Choose an appropriate icon for the Employee Schedules menu item
    //   to: '/employee-schedules',
    //   roles: [ROLES.MANAGER, ROLES.EMPLOYEE] // Define the roles that can access this menu item
    // },
    // {
    //   label: 'Timesheet',
    //   icon: 'employees',
    //   to: '/timesheets',
    //   roles: [ROLES.MANAGER, ROLES.EMPLOYEE]
    // },
    {
      label: 'Reports',
      icon: 'reports',
      to: '/reports',
      roles: [ROLES.MANAGER],
    },
    {
      label: 'Printer',
      icon: 'printer',
      to: '/printers',
      roles: [ROLES.MANAGER],
    },
    // {
    //   label: 'Subscription',
    //   icon: 'subscription',
    //   to: '/subscription',
    //   roles: [ROLES.MANAGER, $auth.user.is_company_owner],
    // },
  ]
    // filter manager / employee view tabs
    .filter(
      menu =>
        store.state.auth.loggedIn
        && menu.roles.includes(store.getters.currentLocation.role),
    )
    // ?? not sure what mode is for...
    .filter((menu) => {
      const mode = $auth.$storage.syncUniversal('mode')
      return !(mode === 'manager' && menu.label === 'POS')
    })
    // filter company owner only tabs
    .filter((menu) => {
      if (menu.roles.includes($auth.user.is_company_owner)) {
        // check if owner
        if (!$auth.user.is_company_owner)
          return false
      }
      return true // are the owner so can view this menu item
    })
}

// area below customer name and info
export function customerMenu(id) {
  const menus = ['tickets', 'payments', 'pickup', 'pay']
  return menus.map((label) => {
    const menu = {}
    menu.to = `/pos/${id}/${label}`
    menu.label = label === 'pickup' ? 'Pick Up' : capitalizeFirstLetter(label)
    menu.icon = label === 'pickup' ? 'hanger' : label
    return menu
  })
}

// area that says Services
export function posMenu({ store, router }) {
  return store.state.services.list.map((service) => {
    return createTicketTypeMenuItem(service, {
      router,
      store,
    })
  })
}
